<template>
  <div class="container">
    <div class="page-content">
      <div class="orderTables">
        <div class="row q-col-gutter-lg q-mb-md">
          <div class="col-2">
            <q-skeleton type="rect" />
          </div>
          <div class="col-6"></div>
          <div class="col-2">
            <q-skeleton type="rect" />
          </div>
          <div class="col-2">
            <q-skeleton type="rect" />
          </div>
        </div>
        <div class="row q-col-gutter-lg q-mb-md" v-for="n in 5" :key="n">
          <div class="col-8">
            <div class="row">
              <div class="col-3 col-sm-2 col-md-1">
                <q-skeleton width="40px" height="40px" />
              </div>
              <div class="col-9 col-sm-10 col-md-11">
                <q-skeleton type="text" />
                <q-skeleton type="text" width="30%" />
              </div>
            </div>
          </div>
          <div class="col-2">
            <q-skeleton type="text" />
          </div>
          <div class="col-2">
            <q-skeleton type="text" />
          </div>
        </div>
      </div>
      <div class="orderSummaryInfo">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3">
            <q-skeleton type="rect" width="50%" class="q-mb-md" />
            <q-skeleton type="text" width="60%" class="q-mb-xs" />
            <q-skeleton type="text" width="40%" class="q-mb-xs" />
            <q-skeleton type="text" width="70%" class="q-mb-xs" />
            <q-skeleton type="text" width="30%" class="q-mb-xs" />
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <q-skeleton type="rect" width="50%" class="q-mb-md" />
            <q-skeleton type="text" width="40%" class="q-mb-xs" />
            <q-skeleton type="text" width="80%" class="q-mb-xs" />
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <q-skeleton type="rect" width="50%" class="q-mb-md" />
            <q-skeleton type="text" width="60%" class="q-mb-xs" />
            <q-skeleton type="text" width="40%" class="q-mb-xs" />
            <q-skeleton type="text" width="70%" class="q-mb-xs" />
            <q-skeleton type="text" width="30%" class="q-mb-xs" />
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <q-skeleton type="rect" width="50%" class="q-mb-md" />
            <div class="row">
              <div class="col-8"
                ><q-skeleton type="text" width="80%" class="q-mb-xs"
              /></div>
              <div class="col-3 offset-1"
                ><q-skeleton type="text" width="100%" class="q-mb-xs"
              /></div>
            </div>
            <div class="row">
              <div class="col-8"
                ><q-skeleton type="text" width="60%" class="q-mb-xs"
              /></div>
              <div class="col-3 offset-1"
                ><q-skeleton type="text" width="100%" class="q-mb-xs"
              /></div>
            </div>
            <div class="row">
              <div class="col-8"
                ><q-skeleton type="text" width="90%" class="q-mb-xs"
              /></div>
              <div class="col-3 offset-1"
                ><q-skeleton type="text" width="100%" class="q-mb-xs"
              /></div>
            </div>
            <div class="row">
              <div class="col-8"
                ><q-skeleton type="rect" width="50%" class="q-mb-xs q-mt-sm"
              /></div>
              <div class="col-3 offset-1"
                ><q-skeleton type="rect" width="100%" class="q-mb-xs q-mt-sm"
              /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="startReturnInfo">
        <div class="row items-center">
          <div class="col-4"><q-skeleton type="rect" width="50%"/></div>
          <div class="col-6">
            <q-skeleton type="text" width="80%" class="q-mb-xs" />
            <q-skeleton type="text" width="40%" class="q-mb-xs" />
          </div>
          <div class="col-2"><q-skeleton width="100%" height="35px"/></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetailSkeleton',
}
</script>
